import { StaticImage } from "gatsby-plugin-image";
import * as React from "react";

import Layout from "../components/Layout";
import SectionContent from "../components/SectionContent";

// markup
const Page = ({ location }) => {
  return (
    <Layout path={location.pathname} title="אירועי דגל">
      <SectionContent title="אירועי דגל">
        <p>
          במהלך המירוץ יתקיימו אירועים מרכזיים, משולבים חיילי חטיבה דרומית בבארי
          וחיילי חטיבה צפונית בחץ השחור ובחוף אשקלון ותלמידים של המועצות מעוטף
          עזה. באירועים הנל תשולב ריצה חברתית. ומורשת קרב על המקום והחיבור בין
          אוגדת עזה, לקהילה. פרטים נוספים בקרוב.
        </p>
        <StaticImage src="../images/ultra-flag-event.jpeg" title="הרשמה אולטרה עוטף עזה 2022" />

      </SectionContent>
    </Layout>
  );
};

export default Page;
